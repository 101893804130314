import React from "react";
import { useState } from "react";
import { useRef } from 'react';
import Styles from './Body.module.css';
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from 'react-spring';
import { Helmet } from 'react-helmet';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Icons
import { TiSocialPinterest } from "react-icons/ti";
import { PiBehanceLogo } from "react-icons/pi";
import { RxInstagramLogo } from "react-icons/rx";
import { AiOutlineLine } from "react-icons/ai";

//Assets
import Contato from "../assets/contact.svg"
import Seguir from "../assets/follow.svg"
import Lampada from "../assets/lampada.png"
import Add from "../assets/add.svg"
import Css3Logo from "../assets/CSS3 Logo.svg"
import FigmaLogo from "../assets/Figma Logo.svg"
import Html5Logo from "../assets/HTML5 Logo.svg"
import JavascriptLogo from "../assets/JavaScript Logo.svg"
import MysqlLogo from "../assets/MYSQL Logo.svg"
import PowerBiLogo from "../assets/Power BI Logo.svg"
import PythonLogo from "../assets/Python Logo.svg"
import ReactLogo from "../assets/React Logo.svg"

function Body() {

    const formRef = useRef(null);
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const notify = () => toast;
    

    function sendEmail(e) {
        e.preventDefault();

        if (name === '' || email === '' || phone === '' || subject === '' || message === '') {
            toast.warning("Preencha todos os campos!");
            return;
        }

        const templateParams = {
            from_name: name,
            email: email,
            phone: phone,
            subject: subject,
            message: message
        }

        emailjs.send("service_c5wyttl", "template_mwvynoh", templateParams, "GwDAHCdyxXmdPowro")
            .then((response) => {
                toast.success('E-mail enviado!', response.status, response.text)
                setName('')
                setEmail('')
                setPhone('')
                setSubject('')
                setMessage('')

            }, (error) => {
                toast.error('Erro ao enviar e-mail!', error)
            })
    }

    const handleChange = (e) => {
        const input = e.target.value;
        // Remove todos os caracteres que não são números
        const cleanedInput = input.replace(/\D/g, '');

        // Verifica se o número de dígitos inseridos é válido (até 11 dígitos)
        if (cleanedInput.length <= 11) {
            // Aplica a formatação para celular
            const formattedInput = formatPhoneNumber(cleanedInput);
            // Atualiza o estado do telefone
            setPhone(formattedInput);
        } else {
            // Se houver mais de 11 dígitos, corta os dígitos extras
            const trimmedInput = cleanedInput.slice(0, 11);
            // Aplica a formatação para celular
            const formattedInput = formatPhoneNumber(trimmedInput);
            // Atualiza o estado do telefone
            setPhone(formattedInput);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        switch (name) {
            case 'name':
                // Verifica se o valor tem até 50 caracteres
                if (value.length <= 50) {
                    setName(value);
                }
                break;
            case 'subject':
                // Verifica se o valor tem até 50 caracteres
                if (value.length <= 50) {
                    setSubject(value);
                }
                break;
            case 'message':
                // Verifica se o valor tem até 255 caracteres
                if (value.length <= 255) {
                    setMessage(value);
                }
                break;
            default:
                break;
        }
    };

    // Função para formatar o número de telefone
    const formatPhoneNumber = (phoneNumber) => {
        // Formatação (99) 99999-9999
        const regex = /^([0-9]{2})([0-9]{0,5})([0-9]{0,4})$/;
        const match = phoneNumber.match(regex);
        if (match) {
            let formattedNumber = '(' + match[1];
            if (match[2]) {
                formattedNumber += ') ' + match[2];
            }
            if (match[3]) {
                formattedNumber += '-' + match[3];
            }
            return formattedNumber;
        }
        // Retorna o número não formatado caso não haja correspondência
        return phoneNumber;
    };

    const scrollToForm = () => {
        formRef.current.scrollIntoView({
            behavior: 'smooth'
        });
    };

    const fadeIn = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        config: { duration: 1000 },
    });

    const { ref: copyRef, inView: copyisVisible } = useInView();
    const { ref: servicesRef, inView: servicesisVisible } = useInView();
    const { ref: contactRef, inView: contactisVisible } = useInView();

    return (
        <>
            <Helmet>
                <title>Felipe Cassiano - Analista de Dados | Desenvolvedor Front-End Jr</title>
                <meta name="description" content="Olá! Meu nome é Felipe Cassiano, sou Analista de Dados e Desenvolvedor Front-End Jr. Estou aqui para auxiliá-lo a transformar seus planos em realidade, seja por meio do desenvolvimento de sites ou impulsionando seu crescimento através da análise de dados." />
                <meta name="keywords" content="Desenvolvedor Front-End, Analista de Dados, React, Desenvolvimento de Sites, Análise de Dados, Dashboards, Portfólio, Interface de Usuário, SEO (Otimização para Mecanismos de Busca), Marketing Digital" />
                <meta name="author" content="Felipe Cassiano" />
            </Helmet>
            <main id="home" className={Styles.verso_final}>
                <animated.div style={fadeIn} className={Styles.home}>
                    <animated.div style={fadeIn} className={Styles.frame}>
                        <div className={Styles.group_home_frases}>
                            <h1 className={Styles.dos_dados_inova_o}>Dos <span className={Styles.back}>dados</span> à inovação, <br />
                                dos <span className={Styles.back}>códigos</span> ao design.</h1>
                            <p className={Styles.text_wrapper}>
                                Unindo o melhor dos dois mundos.
                            </p>
                            <div className={Styles.about}>
                                <p className={Styles.ol_meu_nome_felipe}>
                                    Olá! Meu nome é Felipe Cassiano, sou Analista de Dados e Desenvolvedor Front-End Jr e estou aqui para auxiliá-lo a transformar seus planos em realidade, seja por meio do desenvolvimento de sites ou impulsionando seu crescimento através da análise de dados.
                                </p>
                                <hr className={Styles.line} />
                            </div>
                        </div>
                        <div className={Styles.buttons}>
                            <button className={Styles.btn_contact} onClick={scrollToForm}>
                                <span className={Styles.label}>Contate-me</span>
                                <span className={Styles.icon}>
                                    <img className={Styles.SF_symbol_arrow} alt="Entre em contato" src={Contato} />
                                </span>
                            </button>
                            <a href="https://www.behance.net/felipecassiano1" rel="noreferrer" target="_blank" className={Styles.btn_portfolio}>
                                <span className={Styles.div}>Portfólio</span>
                                <img className={Styles.img} alt="Ir ao Portfólio" src={Seguir} />
                            </a>
                        </div>
                        <div className={Styles.social}>
                            <span className={Styles.text_wrapper_2}>Acompanhe</span>
                            <AiOutlineLine />
                            <a href="https://www.behance.net/felipecassiano1" rel="noreferrer" target="_blank"><PiBehanceLogo className={Styles.linksocial} /></a>
                            <a href="https://www.instagram.com/felipecassianoo/" rel="noreferrer" target="_blank"><RxInstagramLogo className={Styles.linksocial1} /></a>
                            <a href="https://br.pinterest.com/felipecassianoo/" rel="noreferrer" target="_blank"><TiSocialPinterest className={Styles.linksocial_1} /></a>
                        </div>
                    </animated.div>
                    <div className={Styles.img_home}>
                        <div className={Styles.overlap}>
                            <div className={Styles.rectangle} />
                            <img className={Styles.lampada} alt="Inteligência Brilhante" src={Lampada} />
                            <div className={Styles.group}>
                                <div className={Styles.overlap_group}>
                                    <div className={Styles.group_add}>
                                        <div className={Styles.fluent_add_filled}>
                                            <img className={Styles.vector} alt="Somar" src={Add} />
                                        </div>
                                    </div>
                                    <span className={Styles.text_wrapper_3}>Visualização</span>
                                </div>
                            </div>
                            <div className={Styles.overlap_wrapper}>
                                <div className={Styles.overlap_group}>
                                    <div className={Styles.group_add}>
                                        <img className={Styles.fluent_add_filled_2} alt="Agregar" src={Add} />
                                    </div>
                                    <span className={Styles.text_wrapper_4}>Inteligência</span>
                                </div>
                            </div>
                            <div className={Styles.overlap_group_wrapper}>
                                <div className={Styles.overlap_group}>
                                    <div className={Styles.group_add}>
                                        <img className={Styles.fluent_add_filled_2} alt="Agregar" src={Add} />
                                    </div>
                                    <span className={Styles.text_wrapper_5}>Conectividade</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </animated.div>
                <div id="services" className={Styles.group_services}>
                    <div ref={copyRef} className={`${Styles.copy_services} ${copyisVisible ? Styles.animateCopy : ''}`}>
                        <h2 className={Styles.as_melhores_solu_es}>
                            As <span className={Styles.back}>melhores soluções</span> <br />
                            para o seu negócio.
                        </h2>
                        <hr className={Styles.line_2} />
                    </div>
                    <div ref={servicesRef} className={`${Styles.services} ${servicesisVisible ? Styles.animateService : ''}`}>
                        <div className={Styles.div_2}>
                            <span className={Styles.text_wrapper_6}>01/</span>
                            <h2 className={Styles.desenvolvimento_de}>
                                Desenvolvimento <br />
                                de Sites
                            </h2>
                            <p className={Styles.interfaces}>
                                Desenvolvimento de interfaces interativas, acessíveis e eficientes, promovendo a conectividade.
                            </p>
                        </div>
                        <div className={Styles.div_2}>
                            <span className={Styles.text_wrapper_7}>02/</span>
                            <h2 className={Styles.desenvolvimento_de_2}>
                                Desenvolvimento <br />
                                de Dashboards
                            </h2>
                            <p className={Styles.p}>
                                Visualização estratégica para a compreensão holística dos dados e monitoramento de desempenho em tempo real.
                            </p>
                        </div>
                        <div className={Styles.div_2}>
                            <span className={Styles.text_wrapper_8}>03/</span>
                            <h2 className={Styles.analisededados}>
                                Análise de Dados e <br />
                                Relatórios
                            </h2>
                            <p className={Styles.interfaces_2}>
                                Embasar decisões, identificar oportunidades, antecipar desafios e otimizar processos com base em dados.
                            </p>
                        </div>
                    </div>
                </div>
                <div className={Styles.social_proof_section}>
                    <div className={Styles.container}>
                        <div className={Styles.logos}>
                            <img src={PowerBiLogo} alt="Power BI" className={Styles.icon_instance_node} />
                            <img src={MysqlLogo} alt="MySQL" className={Styles.icon_instance_node} />
                            <img src={PythonLogo} alt="Python" className={Styles.icon_instance_node} />
                            <img src={Html5Logo} alt="HTML5" className={Styles.icon_instance_node_2} />
                            <img src={Css3Logo} alt="CSS3" className={Styles.icon_instance_node_2} />
                            <img src={JavascriptLogo} alt="JavaScript" className={Styles.icon_instance_node} />
                            <img src={ReactLogo} alt="React" className={Styles.icon_instance_node} />
                            <img src={FigmaLogo} alt="Figma" className={Styles.icon_instance_node_2} />
                        </div>
                        <div className={Styles.group_pitch}>
                            <div className={Styles.card_pitch}>
                                <div className={Styles.overlap_group_2}>
                                    <div className={Styles.rectangle_2} />
                                    <div className={Styles.rectangle_3} />
                                    <p className={Styles.destaque_se_online}>
                                        Destaque-se online com interfaces institucionais envolventes.
                                    </p>
                                    <p className={Styles.text_wrapper_9}>
                                        Alavanque o sucesso com análise estratégica de dados.
                                    </p>
                                    <p className={Styles.text_wrapper_10}>
                                        Otimize decisões estratégicas através de dashboards de análise de dados eficientes.
                                    </p>
                                    <div className={Styles.rectangle_4} />
                                    <img className={Styles.guidance_up_arrow} alt="Direção" src={Seguir} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <form ref={contactRef} className={`${Styles.contact} ${contactisVisible ? Styles.animateContact : ''}`} onSubmit={sendEmail} >
                    <div className={Styles.frame_2}>
                        <div className={Styles.frame_3}>
                            <h1 className={Styles.vamos_conversar}>
                                Vamos conversar <br />
                                sobre o seu projeto?
                            </h1>
                            <p className={Styles.text_wrapper_11}>
                                Tem alguma grande ideia ou marca para desenvolver e precisa de ajuda?
                            </p>
                        </div>
                        <div className={Styles.frame_4}>
                            <span className={Styles.text_wrapper_12}>E-mail</span>
                            <span className={Styles.text_wrapper_2}>contato@fcassiano.site</span>
                        </div>
                        <div className={Styles.social_2}>
                            <span className={Styles.text_wrapper_12}>Acompanhe</span>
                            <div className={Styles.effect}>
                                <a href="https://www.behance.net/felipecassiano1" rel="noreferrer" target="_blank"><PiBehanceLogo className={Styles.img_2} /></a>
                                <a href="https://www.instagram.com/felipecassianoo/" rel="noreferrer" target="_blank"><RxInstagramLogo className={Styles.instagram} /></a>
                                <a href="https://br.pinterest.com/felipecassianoo/" rel="noreferrer" target="_blank"><TiSocialPinterest className={Styles.img_2} /></a>
                            </div>
                        </div>
                    </div>
                    <div id="contact" className={Styles.frame_5} ref={formRef}>
                        <div htmlFor="name" className={Styles.frame_6}>
                            <label htmlFor="name" className={Styles.text_wrapper_13}>Nome</label>
                            <input type="text" id="name" name="name" value={name} className={Styles.rectangle_5} onChange={handleInputChange} />
                        </div>
                        <div htmlFor="email" className={Styles.frame_6}>
                            <label className={Styles.text_wrapper_13}>E-mail</label>
                            <input type="email" id="email" mask="nome@email.com" value={email} className={Styles.rectangle_5} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div htmlFor="phone" className={Styles.frame_6}>
                            <label className={Styles.text_wrapper_13}>Celular</label>
                            <input id="phone" mask="(99) 99999-9999" value={phone} className={Styles.rectangle_5} onChange={handleChange} inputMode="numeric" />
                        </div>
                        <div htmlFor="subject" className={Styles.frame_6}>
                            <label className={Styles.text_wrapper_13}>Assunto</label>
                            <input type="text" id="subject" name="subject" value={subject} className={Styles.rectangle_5} onChange={handleInputChange} />
                        </div>
                        <div htmlFor="message" className={Styles.frame_6}>
                            <label htmlFor="message" className={Styles.text_wrapper_13}>Mensagem</label>
                            <textarea id="message" name="message" value={message} className={Styles.rectangle_6} onChange={handleInputChange} />
                        </div>
                        <button type="submit" className={Styles.div_wrapper} onClick={notify}>
                            <span className={Styles.text_wrapper_14}>Enviar</span>
                        </button>
                    </div>
                </form>
            </main>
            <ToastContainer position="bottom-right" autoClose="3000" />
        </>
    )
}

export default Body

