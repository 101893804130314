import React, { useState } from 'react';
import styles from './NavBar.module.css';

//Assets
import fclogo from '../assets/logo.png';

function NavBar() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <header>
            <div className={styles.container}>
                <nav id="navigation">
                    <a href="#home" className={styles.logo}><img alt='Felipe Cassiano Logo' src={fclogo}/></a>
                    <a aria-label="mobile menu" className={styles.nav_toggle} onClick={toggleMenu}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </a>
                    <ul className={`${styles.menu_left} ${isOpen ? styles.showMenu : ''}`}>
                        <li><a href="#home">Home</a></li>
                        <li><a href="#services">Serviços</a></li>
                        <li><a href="https://www.behance.net/felipecassiano1" rel='noreferrer' target='_blank'>Projetos</a></li>
                        <li><a href="#contact">Contato</a></li>
                    </ul>
                </nav>
            </div>
        </header>
    );
}
export default NavBar;