import Styles from './Footer.module.css'

function Footer() {
    return (
        <footer className={Styles.footer}>
            <span className={Styles.desenvolvidoby}>Desenvolvido por <a className={Styles.href}href='#home'>Felipe Cassiano</a></span>
        </footer>
    )
}

export default Footer