import './App.css';
import React from 'react';
import NavBar from './components/layout/NavBar';
import Body from './components/layout/Body';
import Footer from './components/layout/Footer';

function App() {
  return (
    <>
    <NavBar />
    <Body />
    <Footer />
    </>
  );
}

export default App;
